import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-toggle-button',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss',
})
export class ToggleButtonComponent {
  @Input() isOpenForm: boolean;
  @Input() openText: string = ' Update Status';
  @Input() closedText: string = 'Cancel Status Updates';
  @Output() toggleRequest = new EventEmitter<boolean>();

  requestToggle() {
    this.toggleRequest.emit();
  }
}
